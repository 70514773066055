import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/client";
import { LoadingContext } from "../Layout";
import ExtensionInfo from "./ExtensionInfo/ExtensionInfo";
import ExtensionReleases from "./ExtensionReleases/ExtensionReleases";

const ExtensionPage = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { workitemId } = useParams();
  const [error, setError] = useState(false);
  const [workitem, setWorkitem] = useState(null);
  const [licenses, setLicenses] = useState(null);

  useEffect(() => {
    showLoading();
    axiosInstance
      .get(`extensions/${workitemId}`)
      .then((response) => {
        setWorkitem(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        window.location.href = "/";
      })
      .then(() => hideLoading());
  }, [workitemId]);

  useEffect(() => {
    showLoading();
    axiosInstance
      .get("license")
      .then((response) => {
        var lics = [];
        response.data?.data?.forEach((lic) => {
          lics.push(lic);
        });
        setLicenses(lics);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        toast.error(error.response?.data.details);
      })
      .then(() => hideLoading());
  }, []);

  return (
    <div>
      {!error ? (
        <div className="container">
          <ExtensionInfo workitemData={workitem} />
          <ExtensionReleases workitemData={workitem} licenses={licenses} />
        </div>
      ) : (
        <h2 className="mt-8">
          There was an error loading the Extension Page.
          <br />
          Please try again later or contact avasis if the problem persists.
        </h2>
      )}
    </div>
  );
};
export default ExtensionPage;
